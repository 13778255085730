import {
  AspectRatio,
  Badge,
  Box,
  BoxProps,
  Flex,
  HStack,
  Image,
  Link,
  Spinner,
  Text,
  Tooltip,
  useColorMode,
  VStack,
} from '@chakra-ui/react'
import { useWallet } from '@solana/wallet-adapter-react'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import * as d from 'date-fns'
import NextLink from 'next/link'
import { useMemo, useState } from 'react'
import { HiOutlineTicket } from 'react-icons/hi'
import { compareTokensForSort } from '../../../utils/tokenSortUtil'
import { formatFloatForDisplay } from '../../../utils/utils'
import { BadgesList } from '../../rewards/components/BadgesCard'
import { PlayerLvl } from '../../rewards/components/PlayerLvl'
import {
  useOnChainRaffle,
  useOnChainRaffleUserData,
} from '../../techRaffles/hooks/raffle'
import { hasRaffleEnded } from '../../techRaffles/raffleUtils'
import {
  OnChainRaffleType,
  OnChainUserRaffleType,
  participantType,
  raffleMinType,
} from '../../techRaffles/types'
import { BuyTicketsOnOverview } from './BuyTicketsOnOverview'
import {
  EnterRewardRaffleOnOverview,
  RewardRaffleStats,
} from './EnterRewardRaffleOnOverview'
import { ProfilePicture } from './ProfilePicture'
import RaffleCountdown from './RaffleCountdown'
import { RaffleStats } from './RaffleStats'
import { SaveRaffleButton } from './SaveRaffleButton'

export type combinedRaffleType = raffleMinType & {
  onChainData?: OnChainRaffleType | null
  onChainUserData?: OnChainUserRaffleType | null
  participantData?: participantType | null
}

type cardType = {
  w?: string | string[]
  h?: string
  minWidth?: BoxProps['minWidth']
  imgH?: string
  raffle: combinedRaffleType
  userId?: string
  showMoreRewards?: boolean
}

export const RaffleCard: (props: cardType) => JSX.Element = (
  props: cardType
) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const {
    w = '100%',
    h = 'unset',
    imgH = 'unset',
    raffle,
    showMoreRewards,
  } = props
  const name = raffle.name
  const flaggedLabel = raffle.creatorUser?.hasBeenFlagged
    ? 'Flagged Raffler'
    : raffle.creatorProject && !raffle.creatorProject.verified
    ? 'Unverified Project'
    : undefined

  /* 
  verified, isRegular, flagged, ...
  
  const isRegularRaffler = false // (raffle.creatorUser?._count.createdRaffles ?? 0) > 4
  const verified = !!flaggedLabel
    ? false
    : raffle.creatorProject?.verified ??
      (raffle.creatorUser?.isTrustedRaffler || isRegularRaffler) ??
      false
  const isTrusted =
    raffle.creatorProject?.verified ?? raffle.creatorUser?.isTrustedRaffler
  const verifiedLabel = verified
    ? raffle.creatorProject?.verified
      ? 'Verified Project'
      : raffle.creatorUser?.isTrustedRaffler
      ? 'Trusted Raffler'
      : isRegularRaffler
      ? 'Regular Raffler'
      : undefined
    : undefined */
  const image =
    raffle.videoUrlMin ??
    raffle.imageUrlMin ??
    raffle.imageUrlMonetMin ??
    raffle.imageUrl
  const isNFTRaffle = raffle.type === 'NFT'
  const isWhitelistRaffle = raffle.type === 'WHITELIST'
  const isIRLRaffle = raffle.type === 'IRL'
  const owner = useMemo(() => {
    if (raffle.isRewardRaffle) return 'Monet Rewards'
    return (
      raffle.creatorProject?.communityName ??
      raffle.creatorUser?.name ??
      `${(raffle.creatorUser?.wallet ?? '').slice(0, 4)}...${(
        raffle.creatorUser?.wallet ?? ''
      ).slice(-4)}`
    )
  }, [raffle])
  const price = raffle.ticketPrice
  const priceToken = raffle.ticketPriceToken?.symbol
  const maxTickets = raffle.maxTickets
  const [imageIsHover, setImageIsHover] = useState(false)
  const wallet = useWallet()

  const [loadOnChainData, setLoadOnChainData] = useState(false)
  const internalOnChainRaffleRes = useOnChainRaffle(raffle.id, loadOnChainData)
  const onChainRaffleData = internalOnChainRaffleRes?.data ?? raffle.onChainData

  const [loadOnChainUserData, setLoadOnChainUserData] = useState(false)
  const internalOnChainUserDataRes = useOnChainRaffleUserData(
    onChainRaffleData,
    !loadOnChainUserData
  )
  const onChainUserData =
    internalOnChainUserDataRes?.data ?? raffle.onChainUserData

  const refetchOnChainData = async () => {
    setLoadOnChainData(true)
    setLoadOnChainUserData(true)
    const onChainDataResult = await internalOnChainRaffleRes.refetch()
    const onChainUserDataResult = await internalOnChainUserDataRes.refetch()
    return { onChainDataResult, onChainUserDataResult }
  }

  const totalTicketsSold = raffle.isRewardRaffle
    ? raffle.ticketsSoldFinal
    : onChainRaffleData?.ticketCount

  const [possiblyEnded, setPossiblyEnded] = useState(false)
  const hasEnded = useMemo(() => {
    return hasRaffleEnded(raffle)
  }, [raffle?.ends, possiblyEnded])

  const allowedPurchaseTokens = useMemo(() => {
    if (!raffle.allowedPurchaseTokens) return []
    return raffle.allowedPurchaseTokens.sort((a, b) =>
      compareTokensForSort(a.token, b.token)
    )
  }, [raffle.allowedPurchaseTokens])

  const userWon = useMemo(() => {
    return (
      !!wallet.publicKey &&
      raffle.status === 'FINISHED' &&
      raffle.winners.some((winner) =>
        winner.wallet === wallet.publicKey?.toBase58()
      )
    )
  }, [wallet, raffle, onChainRaffleData])

  const timeEl = useMemo(() => {
    const today = new Date()

    if (hasEnded) {
      return (
        <Box
          // bg='rgba(255, 255, 255, 0.8)'
          bg='rgba(0, 0, 0, 0.8)'
          color='#fff'
          position='absolute'
          top='.6rem'
          right='.6rem'
          borderRadius='1rem'
          py='0.5rem'
          px='1rem'
          fontWeight='600'
          fontSize='0.75rem'
        >
          Ended at {d.format(raffle.ends, 'dd.MM.yyyy HH:mm')}
        </Box>
      )
    }

    if (raffle.status === 'SCHEDULED' || raffle.status === 'IN_CREATION') {
      if (!d.isAfter(today, raffle.starts) || raffle.status === 'IN_CREATION') {
        return (
          <Box
            bg='rgba(0, 0, 0, 0.8)'
            position='absolute'
            top='.6rem'
            right='.6rem'
            borderRadius='1rem'
            py='0.5rem'
            px='1rem'
            fontWeight='600'
            fontSize='0.75rem'
          >
            Upcoming
          </Box>
        )
      } else if (d.isBefore(today, raffle.ends)) {
        return (
          <Box w='fit-content' pos='absolute' top='.6rem' right='.6rem'>
            <RaffleCountdown
              variant='card'
              ends={raffle.ends}
              raffleEnded={() => {
                setPossiblyEnded(true)
              }}
            />
          </Box>
        )
      }
    }
  }, [raffle, hasEnded])

  const raffleTypeBoxBorder =
    colorMode === 'light'
      ? '1px solid #E9E9E9'
      : '1px solid rgba(255, 255, 255, 0.4)'

  return (
    <Box
      w={w}
      minWidth={props.minWidth}
      h={h}
      bg={colorMode === 'light' ? '#FFFFFF' : 'cardBlack'}
      color={colorMode === 'light' ? 'black' : '#FFF'}
      border={
        userWon
          ? '4px solid #FFBA15'
          : colorMode === 'light'
          ? '1px solid #E9E9E9'
          : '1px solid #393e43'
      }
      borderRadius='1.5rem'
      paddingBottom={raffle.status === 'FINISHED' ? '1rem' : '.5rem'}
      boxShadow={colorMode === 'light' ? 'unset' : 'lg'}
    >
      <Flex paddingTop='0.8rem' paddingBottom='0.6rem' px='.7rem'>
        <HStack spacing='5px'>
          <NextLink
            target='_blank'
            passHref
            href={
              raffle.isRewardRaffle
                ? '#'
                : raffle.isUserRaffle
                ? `/u/${raffle.creatorUser!.wallet}`
                : `/p/${raffle.creatorProject!.publicId}`
            }
          >
            <Link w='100%' fontSize='.875rem'>
              <Flex align='center' gap={2}>
                {!raffle.isRewardRaffle && (
                  <ProfilePicture
                    imageUrl={
                      raffle.isUserRaffle
                        ? raffle.creatorUser?.profilePictureUrl
                        : raffle.creatorProject?.profilePictureUrl
                    }
                    gradientstart={
                      raffle.isUserRaffle
                        ? raffle.creatorUser!.gradientStart
                        : raffle.creatorProject!.gradientStart
                    }
                    gradientend={
                      raffle.isUserRaffle
                        ? raffle.creatorUser!.gradientEnd
                        : raffle.creatorProject!.gradientEnd
                    }
                    w='2.4rem !important'
                    minW='2.4rem'
                    h='2.4rem'
                    rounded='full'
                  />
                )}

                {raffle.isRewardRaffle && (
                  <Box
                    w='2.4rem'
                    h='2.4rem'
                    rounded='full'
                    background={colorMode === 'dark' ? 'white' : 'offblack'}
                    display='flex'
                    justifyContent='center'
                    alignItems={'center'}
                  >
                    <Text
                      fontWeight='900'
                      fontSize={'1.25rem'}
                      fontStyle='normal'
                      fontFamily='PlayfairBlack'
                      textAlign='center'
                      color={colorMode === 'dark' ? 'black' : '#fff'}
                    >
                      M
                    </Text>
                  </Box>
                )}

                <Text
                  isTruncated
                  maxW={raffle.isUserRaffle ? '8rem' : '9rem'}
                >
                  {owner}
                </Text>
              </Flex>
            </Link>
          </NextLink>
          {raffle.creatorProject?.verified && (
            <Tooltip label={'Verified Project'}>
              <Image
                w='25px'
                m={'-6px !important'}
                ml={'6px !important'}
                src={`/images/rewards/badges/project.svg`}
              />
            </Tooltip>
          )}
          {/*verifiedLabel && (
                <Tooltip label={verifiedLabel}>
                  <Text as='span'>
                    <BsFillPatchCheckFill
                      color={isTrusted ? 'green' : 'gray'}
                    />
                  </Text>
                </Tooltip>
              )*/}
          {raffle.isUserRaffle &&
            raffle.creatorUser &&
            (raffle.creatorUser.experiencePoints < 10 ? (
              <Tooltip label='New user'>
                <Image
                  w='40px'
                  m={'-6px !important'}
                  ml={'0px !important'}
                  src={`/images/rewards/badges/new.svg`}
                />
              </Tooltip>
            ) : (
              <Flex justifyContent={'center'} h='40px' w='40px' paddingX='3px'>
                <Flex w={'25px'}>
                  <PlayerLvl
                    experiencePoints={raffle.creatorUser?.experiencePoints}
                  ></PlayerLvl>
                </Flex>
              </Flex>
            ))}
          {raffle.isUserRaffle &&
            (raffle.creatorUser?.badges &&
            raffle.creatorUser.badges.length > 2 ? (
              <HStack ml={1} h='40px' paddingY='2px'>
                <BadgesList
                  imageW='40px'
                  m={'-6px !important'}
                  badges={raffle.creatorUser?.badges}
                ></BadgesList>
              </HStack>
            ) : (
              <BadgesList
                imageW='40px'
                m={'-6px !important'}
                badges={raffle.creatorUser?.badges}
              ></BadgesList>
            ))}
        </HStack>
        {(isWhitelistRaffle || isIRLRaffle) && (
          <Box
            border={raffleTypeBoxBorder}
            borderRadius='1.5rem'
            display='flex'
            justifyContent='center'
          >
            {isNFTRaffle && (
              <HStack p='.5rem' gap='0.25rem'>
                <HiOutlineTicket />
                <Text m={'0 !important'} fontWeight='500' fontSize='0.825rem'>
                  Raffle
                </Text>
              </HStack>
            )}

            {isWhitelistRaffle && (
              <HStack p='.5rem' gap='0.25rem'>
                <HiOutlineTicket />
                <Text m={'0 !important'} fontWeight='500' fontSize='0.825rem'>
                  WL
                </Text>
              </HStack>
            )}

            {isIRLRaffle && (
              <HStack p='.5rem' gap='0.25rem'>
                <HiOutlineTicket />
                <Text m={'0 !important'} fontWeight='500' fontSize='0.825rem'>
                  IRL
                </Text>
              </HStack>
            )}
          </Box>
        )}
      </Flex>
      {flaggedLabel && (
        <Flex px='1rem' pb='.6rem'>
          <Badge mt='1' variant='subtle' colorScheme='yellow'>
            {flaggedLabel}
          </Badge>
        </Flex>
      )}
      <Box
        position='relative'
        onMouseEnter={() => setImageIsHover(true)}
        onMouseLeave={() => setImageIsHover(false)}
      >
        <NextLink
          target='_blank'
          passHref
          href={raffle.isRewardRaffle ? `` : `/r/${raffle.id}`}
        >
          <Link w='100%'>
            <Box
              overflow={'hidden'}
              borderTop={isDarkMode ? 'none' : '1px solid #E9E9E9'}
              borderBottom={isDarkMode ? 'none' : '1px solid #E9E9E9'}
            >
              <AspectRatio ratio={1}>
                <Image
                  w={'100%'}
                  sizes='(max-width: 768px) 95vw,
                         (max-width: 992px) 48vw,
                         (max-width: 1280px) 33vw
                          25vw'
                  h={imgH}
                  src={image}
                  fallback={
                    <Image
                      w={'100%'}
                      sizes='(max-width: 768px) 95vw,
                            (max-width: 992px) 48vw,
                            (max-width: 1280px) 33vw
                              25vw'
                      h={imgH}
                      src={raffle.imageUrl}
                      alt={raffle.name}
                      objectFit='cover'
                      transition={'transform .5s ease-in-out'}
                      _hover={{
                        transform: 'scale(1.05)',
                      }}
                    ></Image>
                  }
                  alt={raffle.name}
                  objectFit='cover'
                  transition={'transform .5s ease-in-out'}
                  _hover={{
                    transform: 'scale(1.05)',
                  }}
                ></Image>
              </AspectRatio>
            </Box>
          </Link>
        </NextLink>
        {timeEl}

        {raffle.collection && raffle.collection.floorPrice && (
          <Box position='absolute' left='.4rem' bottom='10px'>
            <Badge
              textTransform={'none'}
              backgroundColor={'rgba(0, 0, 0, 0.65)'}
              fontWeight={600}
              color='white'
              size={'sm'}
              px='6px'
              py='2px'
              mr='6px'
              fontSize='.625rem'
              borderRadius={'full'}
            >
              FP:{' '}
              {formatFloatForDisplay(
                raffle.collection.floorPrice / LAMPORTS_PER_SOL
              )}
              &#9678;
              {/*!!raffle?.collection?.averagePrice24hr ?? 0 && (
                <>
                  {' '}
                  | AVG:{' '}
                  {formatFloatForDisplay(
                    raffle.collection?.averagePrice24hr / LAMPORTS_PER_SOL
                  )}
                  &#9678;
                </>
                  )*/}
            </Badge>
          </Box>
        )}

        {onChainRaffleData &&
          raffle.estimateTicketPriceInSol &&
          allowedPurchaseTokens.some((token) => token.token.onDEX) && (
            <Box position='absolute' right='.4rem' bottom='10px'>
              <Badge
                textTransform={'none'}
                backgroundColor={'rgba(0, 0, 0, 0.65)'}
                fontWeight={600}
                color='white'
                size={'sm'}
                px='6px'
                py='2px'
                borderRadius={'full'}
                fontSize='.625rem'
              >
                Vol:{' '}
                {formatFloatForDisplay(
                  raffle.estimateTicketPriceInSol *
                    onChainRaffleData.ticketCount
                )}
                &#9678;
                {raffle.maxTickets && (
                  <>
                    {' '}
                    | TTV:{' '}
                    {formatFloatForDisplay(
                      raffle.estimateTicketPriceInSol * raffle.maxTickets
                    )}
                    &#9678;
                  </>
                )}
              </Badge>
            </Box>
          )}

        {!raffle.isRewardRaffle && imageIsHover && (
          <NextLink passHref href={`/r/${raffle.id}`} target='_blank'>
            <Link
              padding='0.5rem 1.25rem'
              rounded='full'
              border='none'
              position='absolute'
              left='50%'
              transform='translateX(-50%)'
              bottom='2rem'
              bg='#232323'
              color='#fff'
              fontSize='1rem'
              fontWeight='500'
              borderColor='#232323'
              _hover={{
                bg: '#000',
                border: '#000',
              }}
            >
              View Raffle
            </Link>
          </NextLink>
        )}
      </Box>

      {raffle.type === 'NFT' && !!raffle.collection?.name && (
        <NextLink
          passHref
          href={`/collections/${raffle.collection?.name}`}
          target='_blank'
        >
          <Link pt='.6rem' px='.7rem'>
            <Flex alignItems={'center'}>
              <Image
                display='inline'
                mr='4px'
                w='16px'
                h='16px'
                src='/twitter-images/verified/verified.png'
              ></Image>
              <Text opacity={0.8} fontSize={'0.875rem'}>
                {raffle.collection?.title}
              </Text>
            </Flex>
          </Link>
        </NextLink>
      )}
      <Flex justify='space-between' px='.7rem' alignItems='baseline' mb='.4rem'>
        <NextLink
          target='_blank'
          passHref
          href={raffle.isRewardRaffle ? `` : `/r/${raffle.id}`}
        >
          <Link
            display='block'
            height='100%'
            marginY={wallet.publicKey ? '0' : '12px'}
          >
            <Text
              isTruncated
              maxWidth='20rem'
              fontSize='1.1rem'
              fontWeight={600}
            >
              {name}
            </Text>
          </Link>
        </NextLink>
        <SaveRaffleButton raffleId={raffle.id} />
      </Flex>

      <Flex mt='.5rem' px='.7rem' justify='space-between'>
        {userWon ? (
          <Box>
            <Text fontSize='1.5rem' fontWeight='700'>
              You won! 🎉
            </Text>
          </Box>
        ) : totalTicketsSold && maxTickets && totalTicketsSold >= maxTickets ? (
          <Box>
            <Text fontWeight='700' fontSize='1.2rem' color='#f13a3a'>
              Sold out 🔥
            </Text>
            <Text fontWeight='400' fontSize='0.75rem' color='#BDBDBD'>
              {totalTicketsSold} Tickets sold
            </Text>
          </Box>
        ) : (
          <Box>
            <Text fontWeight='500' fontSize='1rem'>
              {totalTicketsSold ?? <Spinner size='sm' mt='0.3rem' />}{' '}
              {maxTickets && <span>/ {maxTickets}</span>}
            </Text>

            <Text
              fontWeight='400'
              fontSize='0.75rem'
              color='#BDBDBD'
              textAlign='left'
            >
              Tickets sold
            </Text>
          </Box>
        )}
        {raffle.isRewardRaffle ? (
          <Box
            color='#FFC728'
            bg={isDarkMode ? 'primaryDark' : '#FFF8E4'}
            h='40px'
            w='120px'
            border={isDarkMode ? 'solid 1px #FFffff50' : 'solid 1px #FFDBB9'}
            borderRadius='full'
            display='flex'
            justifyContent='center'
            alignItems='center'
            fontWeight='bold'
            gap='2'
          >
            <Image src='/images/rewards/GemsShine.svg' />
            <Text color={isDarkMode ? 'white' : '#FF8F28'}>
              {formatFloatForDisplay(price)}
            </Text>
          </Box>
        ) : (
          <Box>
            <Text fontWeight='500' fontSize='1rem' textAlign={'right'}>
              {price} {priceToken}
            </Text>
            <Text
              fontWeight='400'
              fontSize='0.75rem'
              color='#BDBDBD'
              textAlign='right'
            >
              Ticket Price
            </Text>
          </Box>
        )}
      </Flex>
      {!raffle.isRewardRaffle && allowedPurchaseTokens && (
        <Flex justify='space-between' px='.7rem' pt='.6rem' alignItems='center'>
          {/*<Box>
            <Box mb='0rem'>
              {allowedPurchaseTokens.slice(0, 3).map((token) => (
                <Text
                  key={token.token.symbol}
                  as='span'
                  fontSize='.8rem'
                  rounded='full'
                  mr='.5rem'
                  fontWeight={500}
                >
                  {token.token.symbol}
                </Text>
              ))}
              {allowedPurchaseTokens.length > 3 && (
                <Text
                  as='span'
                  fontSize='.75rem'
                  rounded='full'
                  fontWeight={400}
                  color='#BDBDBD'
                >
                  & more
                </Text>
              )}
            </Box>
            <Text
              fontWeight='400'
              fontSize='0.75rem'
              color='#BDBDBD'
              textAlign='left'
            >
              Accepted
            </Text>
              </Box>*/}

          {raffle.pointsMultiplier > 1 && (
            <Flex h={'100%'} justifyContent={'center'} alignItems='center'>
              <HStack
                paddingY={1}
                paddingX={2}
                borderRadius={'full'}
                border={'1px solid #FF8F28'}
              >
                <Text m={'0 !important'} fontWeight={500}>
                  {' '}
                  {formatFloatForDisplay(raffle.pointsMultiplier)}x
                </Text>
                <Image src='/images/rewards/GemsShine.svg' />
              </HStack>
            </Flex>
          )}
        </Flex>
      )}

      {raffle.status === 'SCHEDULED' && !hasEnded && (
        <>
          {raffle.isRewardRaffle ? (
            <EnterRewardRaffleOnOverview
              raffle={raffle}
              participantData={raffle.participantData}
              userId={props.userId}
              variant='card'
            ></EnterRewardRaffleOnOverview>
          ) : (
            wallet.publicKey && (
              <BuyTicketsOnOverview
                raffle={raffle}
                onChainUserData={onChainUserData ?? null}
                onChainRaffleRes={onChainRaffleData ?? null}
                refetchOnChainData={refetchOnChainData}
                totalTicketsSold={totalTicketsSold ?? 0}
                variant='card'
              />
            )
          )}
        </>
      )}
      {raffle.status === 'FINISHED' && onChainRaffleData && (
        <RaffleStats
          raffle={raffle}
          onChainUserData={onChainUserData ?? null}
          onChainRaffleRes={onChainRaffleData}
          hasEnded={true}
        ></RaffleStats>
      )}
      {(raffle.status !== 'SCHEDULED' || hasEnded) && raffle.isRewardRaffle && (
        <RewardRaffleStats participant={raffle.participantData} />
      )}
      {raffle.isRewardRaffle && (
        <VStack px='.7rem' mt='1rem'>
          <Text fontSize='.875rem' textAlign={'center'}>
            Reward Raffle by Monet. Enter with gems that you earn simply by
            using Monet
          </Text>
        </VStack>
      )}

      {hasEnded && (
        <Box mt='1.5rem' px='1rem'>
          {raffle.status === 'SCHEDULED' && hasEnded && (
            <Text fontSize='1rem' fontWeight={600} textAlign={'center'}>
              Draw pending
            </Text>
          )}
          {raffle.status === 'FINISHED' && raffle.winners.map((w) => (
            <Text
              key={w.id}
              fontSize='1rem'
              fontWeight={600}
              textAlign={'center'}
            >
              🎉 Winner:{' '}
              <Link
                display='inline'
                target={'_blank'}
                textDecor={'underline'}
                href={`/u/${w.wallet}`}
              >
                {w.name}
              </Link>
            </Text>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default RaffleCard
