import { trpc } from '../../../utils/trpc'
import { useRouter } from 'next/router'
import { useProjectId } from './project'
import { useQuery } from 'react-query'
import {
  getAllParticipantRaffles,
  getAllRaffleParticipants,
  getRaffleOnChainDataByDBId,
  getRafflesAndRaffleUsersOnChainByDBIds,
  getRafflesOnChainByDBIds,
  getRaffleUser,
} from '../raffleOnChainUtils'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletFromRouter } from './user'
import { useUser } from '../../common/auth/authHooks'
import { PublicKey } from '@solana/web3.js'
import { useAsync } from 'react-use'
import {
  raffleOrderByType,
  raffleCategoryFilterType,
  raffleType,
  raffleProjectFilterType,
  raffleStatusType,
  OnChainRaffleType,
  raffleUserConnectionType,
  raffleMinType,
  raffleTypeWithLikeCount,
} from '../types'

export function useRafflesEndingAtSimilarTime(date: Date) {
  return trpc.useQuery(['raffle.ending-at-similar-time', { date }])
}

export function useAllRaffles(props: {
  filter: raffleCategoryFilterType
  orderBy: raffleOrderByType
  status: raffleStatusType
  page: number
}) {
  return trpc.useQuery([
    'raffle.all-new',
    {
      raffleCategoryFilter: props.filter,
      orderBy: props.orderBy,
      status: props.status,
      page: props.page,
    },
  ])
}

export function useTrendingRaffles() {
  return trpc.useQuery(['raffle.trending.cache'], {
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 2,
  })
}

export function useRafflesAndUserRafflesByProjectPublicId(props: {
  filter: raffleProjectFilterType
  orderBy: raffleOrderByType
  status: raffleStatusType
  page: number
}) {
  const projectId = useProjectId()!
  return trpc.useQuery([
    'raffle.all-by-project-public-id',
    {
      projectPublicId: projectId,
      filter: props.filter,
      orderBy: props.orderBy,
      status: props.status,
      page: props.page,
    },
  ])
}

export function useUserRaffles(props: {
  userWallet?: string
  raffleType: raffleUserConnectionType
  page: number
}) {
  return trpc.useQuery(
    [
      'raffle.all-user',
      {
        userWallet: props.userWallet ?? '',
        type: props.raffleType,
        page: props.page,
      },
    ],
    { enabled: !!props.userWallet }
  )
}

export function useCollectionRafflesBySlug(page: number) {
  const router = useRouter()
  return trpc.useQuery(
    [
      'raffle.all-collection',
      { collectionName: router.query.collectionName as string, page: page },
    ],
    { enabled: !!router.query.collectionName }
  )
}

export function useRewardRaffles(page: number) {
  const router = useRouter()
  return trpc.useQuery(['raffle.all-reward-raffles', { page }])
}

export function usePastRewardRaffles(page: number) {
  const router = useRouter()
  return trpc.useQuery(['raffle.past-reward-raffles', { page }])
}

export function useMyRewardRaffles(page: number, userId?: string) {
  const router = useRouter()
  return trpc.useQuery(
    ['raffle.my-reward-raffles', { page, userId: userId ?? '' }],
    { enabled: !!userId }
  )
}

export function useOnChainRaffleListData(
  raffles?: (raffleMinType | raffleType | null)[] | null,
  user?: PublicKey | null
) {
  const allRafflesRes = useAsync(async () => {
    const existingRaffles = (raffles ?? []).filter(
      (r) => !!r?.id
    ) as raffleMinType[]
    const {
      allExistingOnChainRaffleAccounts,
      allExistingOnChainRaffleUserAccounts,
    } = await getRafflesAndRaffleUsersOnChainByDBIds(
      existingRaffles.map((r) => r.id),
      user
    )
    const allRafflesCombined = existingRaffles.map((r) => {
      const onChainData = allExistingOnChainRaffleAccounts.find(
        (a) => a?.id === r?.id
      )
      const onChainUserData = allExistingOnChainRaffleUserAccounts.find(
        (userData) =>
          userData?.raffle &&
          userData.raffle.toBase58() === onChainData?.publicKey?.toBase58()
      )

      return {
        ...r,
        onChainData,
        onChainUserData,
      }
    })

    return allRafflesCombined
  }, [raffles])
  return allRafflesRes
}

export function useAllRewardRafflesAdmin() {
  return trpc.useQuery(['raffle.allRewardsRafflesAdmin'])
}

export function useRafflesByProjectPublicId() {
  const projectId = useProjectId()!
  return trpc.useQuery([
    'raffle.all-admin-by-project-public-id',
    { projectPublicId: projectId, includeEnded: true },
  ])
}

export function useRaffleBySlug(initialData?: raffleTypeWithLikeCount) {
  const raffleId = useRouter().query.id as string | undefined
  return trpc.useQuery(['raffle.single', { id: raffleId ?? '' }], {
    enabled: !!raffleId,
    initialData,
  })
}

export function useOnChainRaffle(raffleId?: string, enabled: boolean = false) {
  return useQuery(
    ['raffleOnChain', raffleId],
    async () => {
      if (!raffleId) return
      return getRaffleOnChainDataByDBId(raffleId as string)
    },
    { enabled: !!raffleId && enabled }
  )
}

export function useOnChainRaffleUserData(
  raffle?: OnChainRaffleType | null,
  deactivated: boolean = false,
  onChainUserRaffleData?: any
) {
  const wallet = useWallet()
  return useQuery(
    [
      'raffleUserOnChain',
      raffle?.id,
      wallet.publicKey,
      deactivated,
      onChainUserRaffleData?.counter,
    ],
    async () => {
      if (onChainUserRaffleData) return onChainUserRaffleData
      if (!raffle || !wallet.publicKey || deactivated) return
      return getRaffleUser({ raffle: raffle.publicKey, user: wallet.publicKey })
    },
    { enabled: !!raffle && !!wallet.publicKey }
  )
}

export function useOnChainRaffleParticipants(
  raffleKey?: PublicKey,
) {
  return useQuery(
    ['useOnChainRaffleParticipants', raffleKey],
    async () => {
      if (!raffleKey) return
      const raffleUsers = await getAllRaffleParticipants(raffleKey)
      console.log('raffleUsers', raffleUsers)
      return raffleUsers
        .filter((u) => u.account.raffle.equals(raffleKey))
        .sort((a, b) =>
          a.account.authority
            .toBase58()
            .localeCompare(b.account.authority.toBase58())
        )
    }
  )
}

export function useRafflesSavedByUser(enabled: boolean) {
  const { isAuthed } = useUser()
  return trpc.useQuery(['raffle.saved', { includeEnded: true }], {
    enabled: isAuthed && enabled,
  })
}

export function useIsRaffleSavedByUser(
  raffleId: string | undefined,
  walletPubkey: string | undefined
) {
  const { isAuthed } = useUser()
  return trpc.useQuery(
    [
      'raffle.liked',
      { raffleId: raffleId ?? '', userWalletBase58: walletPubkey },
    ],
    {
      enabled: (isAuthed || !!walletPubkey) && !!raffleId,
      retry: 2,
      retryDelay: 10000,
    }
  )
}

export function useRaffleWinnerImage(raffleId?: string) {
  return trpc.useQuery(
    ['raffle.getWinnerImage', { raffleId: raffleId ?? '' }],
    { enabled: !!raffleId }
  )
}
